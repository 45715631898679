<template>
  <div class="feed-body">
    <div class="feed-contents">
      <div class="d-flex flex-row w-100 justify-between mt-5 mb-3">
        <span class="white--text font-30 app-medium-font">My donations</span>
        <div
          class="d-flex flex-row align-center"
          v-if="profileType == 'Agency'"
        >
          <span class="white--text pr-2 mt-1">{{
            filter ? "Received" : "Paid"
          }}</span>
          <v-switch
            v-model="filter"
            hide-details
            class="ma-0 pa-0"
            dark
            @change="onChangeFilter"
          />
        </div>
      </div>
      <div class="mt-4 w-100 mb-5 px-3">
        <v-data-table
          :headers="headers"
          :items="$route.query.filter ? filteredDonations() : donations"
          :items-per-page="15"
          :loading="loading"
          @click:row="onSelectDonation"
          class="elevation-0"
        >
          <template v-slot:[`item.receiver`]="{ item }">
            <div
              @click="goToProfile(item.receiver._id, item.receiver_type)"
              style="width: 200px;"
              class="d-flex flex-column align-center mt-3"
            >
              <v-img
                :src="
                  item.receiver.bgImage
                    ? item.receiver.bgImage
                    : require('@/assets/background0.png')
                "
                width="200"
                style="flex-grow: 1"
                :aspect-ratio="
                  $vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455
                "
                class="rounded-lg rounded-b-0 position-absolute"
              />
              <editable-avatar
                :image="
                  item.receiver.thumbnail
                    ? item.receiver.thumbnail
                    : item.receiver.image
                "
                :editable="false"
                :size="50"
                class="mt-5"
              />
            </div>
            <div
              class="url-button app-bold-font"
              @click="goToProfile(item.receiver._id, item.receiver_type)"
            >
              {{ item.receiver.name }}
            </div>
            <div>
              {{ formatPhoneNumber(item.receiver.phone) }}
              <v-tooltip bottom v-if="item.receiver.phone_verified">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="14" class="ml-1" v-bind="attrs" v-on="on"
                    >mdi-check-circle</v-icon
                  >
                </template>
                Phone verified
              </v-tooltip>
            </div>
            <div>
              {{ item.receiver.email }}
              <v-tooltip bottom v-if="item.receiver.email_verified">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="14" class="ml-1" v-bind="attrs" v-on="on"
                    >mdi-check-circle</v-icon
                  >
                </template>
                Email verified
              </v-tooltip>
            </div>
            <div class="d-flex flex-wrap mb-3">
              <site-icon
                :name="icon"
                type="Cause"
                v-for="(icon, key) in item.causes"
                :key="key"
                :size="30"
                class="mr-3 mt-2"
              />
            </div>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.pay_in`]="{ item }">
            {{ formatCurrency(item.pay_in / 100) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div>
              {{ item.status }}<br />
              {{ item.status_description }}
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import dateFormat from "dateformat";
export default {
  components: { EditableAvatar },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      headers: [
        { text: "Agency", value: "receiver" },
        { text: "Description", value: "description" },
        { text: "Amount", value: "pay_in" },
        { text: "Date", value: "createdAt" },
        { text: "Status", value: "status" },
      ],
      filter: false,
    };
  },
  methods: {
    ...mapActions({
      fetchAllDonations: "donation/fetchAllDonations",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onLoadDonations() {
      this.loading = true;
      this.loadCompleted = 0;
      this.fetchAllDonations()
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    onSelectDonation(donation) {
      this.$router.push({
        name: "donation-details",
        query: { _id: donation._id },
      });
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    formatCurrency(numberString) {
      const number = parseFloat(numberString);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(number);
    },
    formatDate(date) {
      return dateFormat(
        this.convertTZ(date, this.timezone),
        "mm/dd/yyyy hh:MM TT"
      );
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    goToProfile(id, type) {
      let routeData = this.$router.resolve({
        name: `${type.toLowerCase()}-profile-details`,
        params: { id },
      });
      window.open(routeData.href, "_blank");
    },
    onChangeFilter() {
      if (
        (this.$route.query.filter == "paid" && this.filter) ||
        (this.$route.query.filter == "received" && !this.filter)
      )
        this.$router.replace({
          query: {
            filter: this.filter ? "received" : "paid",
          },
        });
    },
    filteredDonations() {
      return this.donations.filter((donation) => {
        if (this.$route.query.filter === "received") {
          return donation.receiver._id === this.profile._id;
        } else if (this.$route.query.filter == "paid") {
          return donation.payer._id === this.profile._id;
        } else if (!this.$route.query.filter) {
          return true;
        }
        return false;
      });
    },
  },
  computed: {
    ...mapGetters({
      donations: "donation/getAllDonations",
      profileType: "auth/getType",
      profile: "auth/getProfile",
    }),
  },
  mounted() {
    if (this.$route.query.filter == "received") {
      this.filter = true;
    } else {
      this.filter = false;
    }
    this.onLoadDonations();
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
</style>
